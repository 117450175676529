import React from "react";
import { Link } from "react-router-dom";
import { Button } from "reactstrap";
import Card from "./Card";

const Pool = () => {
  return (
    <div className="card-container">
      <h1>
        {" "}
        LUM EARN UP TO <span className="percentage">240% APY</span>
      </h1>
      <div className="poolscontainer ">
      <div style={{ width: "100%" }} >
        <Card
          className="dai-usdt-card"
          image="image/usdt.png"
          name="DAI-USDT"
          day1="90"
          day2="120"
          day3="150"
          stake="Stake"
          reminder="more➣"
          days="Duration(Days)"
        />
        </div>

        <div  style={{ width: "100%" }}>
          <Card
            className="lum"
            image="image/logo.png"
            name="LUM"
            day1="50"
            day2="90"
            day3="150"
            stake="Stake"
            reminder="more➣"
            days="Duration(Days)"
          />
        </div>
        <div className="card-not-clickable" style={{ width: "100%" }}>
          <Card
            className="matic"
            image="image/logo.png"
            name="MATIC"
            day1="100"
            day2="120"
            day3="150"
            stake="ComingSoon"
            reminder="More ➣"
            days="Duration(Days)"
          />
        </div>
      </div>
      <div className="Airdrop-section">
        <div>
          <h1>LUM Airdrop</h1>
        </div>
        <div className="Airdrop-card">
          <p>Token Name: LUM</p>
          <p>Airdrop Amount: 20LUM ~~ 20$</p>
          <p>Airdrop Refferal Bonus = 5%</p>
          <Link to="/dashboard">
            <div style={{ width: "100%" }}>
              <Button active color="primary" outline size="lg">
                {" "}
                Claim LUM Airdrop
              </Button>
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Pool;
